<template>
  <div>
    <div class="mb-4">
      <a-table
        :columns="columns"
        :dataSource="items"
        :pagination="true"
        :class="$style.table"
        rowKey="id"
        :loading="isLoading"
      >
        <template slot="code" slot-scope="code, record">
          <div>
            <div v-if="isAdmin">
              <router-link
                :to="{ path: '/voucher/details/' + record.id }"
                class="kit__utils__link font-size-16"
                >{{ record.code }}</router-link
              >
            </div>
            <div v-else>{{ record.code }}</div>
            <!-- <div class="text-gray-4">{{record.tag? record.tag: '-'}}</div> -->
          </div>
        </template>
        <template slot="testLabType" slot-scope="testLabType">
          <div>
            <div>{{ testLabType ? testLabType.name : '-' }}</div>
          </div>
        </template>
        <template slot="createdAt" slot-scope="createdAt">
          <div>
            <div>
              {{ createdAt ? moment(createdAt).format('DD MMM YYYY') : '-' }}
            </div>
          </div>
        </template>
        <template slot="status" slot-scope="status">
          <div>
            <div>{{ status ? 'Aktif' : 'Tidak Aktif' }}</div>
          </div>
        </template>
      </a-table>
    </div>
  </div>
</template>
<script>
import { getAll } from '@/services/axios/api/voucher'
import moment from 'moment'
const columns = [
  {
    title: 'Kode Voucher',
    dataIndex: 'code',
    className: 'bg-transparent text-gray-6 width-50',
    scopedSlots: { customRender: 'code' },
    sorter: (a, b) => {
      return a.code.localeCompare(b.code)
    },
  },
  {
    title: 'Jenis Tes Laboratorium',
    dataIndex: 'testLabType',
    className: 'bg-transparent text-gray-6 width-50',
    scopedSlots: { customRender: 'testLabType' },
    sorter: (a, b) => {
      return a.testLabType.name.localeCompare(b.testLabType.name)
    },
    filters: [],
    onFilter: (value, record) => {
      return record.testLabType.name === value
    },
  },
  {
    title: 'Tanggal Dibuat',
    dataIndex: 'createdAt',
    className: 'bg-transparent text-gray-6 width-50',
    scopedSlots: { customRender: 'createdAt' },
    sorter: (a, b) => {
      return a.createdAt.localeCompare(b.createdAt)
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    className: 'bg-transparent text-gray-6 width-50',
    scopedSlots: { customRender: 'status' },
    sorter: (a, b) => a.status - b.status,
  },
]

export default {
  name: 'TabelVoucher',
  data: function () {
    return {
      columns,
      items: [],
      isLoading: true,
      isAdmin: false,
    }
  },
  created() {
    this.getListData()
    this.getRole()
  },
  methods: {
    moment,
    getRole() {
      const role = window.localStorage.getItem('role')
      // console.log(role)
      if (role === 'admin') {
        this.isAdmin = true
      }
    },
    async getListData() {
      const testLabFilters = []
      const res = await getAll()
      for (const i in res) {
        const voucher = res[i]
        console.log(voucher)
        if (voucher.testLabType) {
          if (!testLabFilters.includes(voucher.testLabType.name)) {
            testLabFilters.push(voucher.testLabType.name)
          }
        }
      }
      columns[1].filters = []
      testLabFilters.forEach((el) => {
        columns[1].filters.push({
          text: el,
          value: el,
        })
      })
      this.columns = columns
      this.isLoading = false
      this.items = res
    },
  },
}
</script>
<style lang="scss" module>
@import './style.module.scss';
</style>
